import { FC } from 'react'

type Props = {
  width?: number
  height?: number
  fill?: string
  idSuffix?: string
}

export const IconSmallLogo: FC<Props> = ({
  width,
  height,
  fill,
  idSuffix = 'default',
}) => {
  // 同じページ内に複数のsvgが表示されたとき、defsタグで定義している要素のIDが重複する。そのとき、各箇所では最初にレンダリングされたものを参照しようとするが、
  // その要素が非表示になったときに参照するグラデーションが適用されない現象が発生していた。IDにidSuffixを付与して一意性を保つことで解決。
  // 参考：https://www.antonball.dev/blog/2020-06-15-svg-id-collision/
  const clipPathId = `clip_id_${idSuffix}`
  const linearGradient0Id = `linear_gradient_0_${idSuffix}`
  const linearGradient1Id = `linear_gradient_1_${idSuffix}`

  return (
    <svg
      fill={fill || 'none'}
      height={height}
      viewBox="0 0 25 31"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M21.9942 22.8969H19.4573L21.3791 28.4802H3.6207L5.5425 22.8969H3.00558L0.257568 30.8788H24.7422L21.9942 22.8969Z"
          fill={fill || `url(#${linearGradient0Id})`}
        />
        <path
          d="M11.5227 26.7113C10.8421 23.774 9.69553 21.2517 8.12316 19.2207C7.12222 17.9249 6.32875 16.7675 5.02208 14.853L5.00388 14.8275C3.88284 13.186 3.31504 11.3697 3.36235 9.57534C3.44971 6.29592 5.33874 4.03927 5.91746 3.42415C6.57626 2.72168 9.00034 0.435913 12.5127 0.435913C16.0251 0.435913 18.4637 2.72168 19.1225 3.42415C19.7012 4.03927 21.5903 6.29592 21.6776 9.57534C21.7395 11.8429 20.8186 13.6846 20.0361 14.8275C19.5411 15.5518 19.1189 16.1669 18.733 16.7238L18.744 16.7311C18.0342 17.7539 17.5064 18.4746 16.9314 19.2243C15.359 21.2553 14.2161 23.7777 13.5318 26.715H11.519L11.5227 26.7113ZM12.5163 2.49966C9.83384 2.49966 7.94117 4.28678 7.42796 4.83638C7.10766 5.17852 5.50253 7.01295 5.43337 9.6263C5.38606 11.3406 6.10309 12.7674 6.71457 13.6592C8.02124 15.57 8.79651 16.7093 9.76105 17.9541C10.8857 19.41 11.8139 21.077 12.5309 22.9405C13.2479 21.0806 14.1724 19.4172 15.2971 17.9613L15.2862 17.9541C16.2507 16.7056 17.0296 15.5664 18.3181 13.681C18.9442 12.7674 19.6612 11.3406 19.6139 9.6263C19.5447 7.01295 17.9396 5.17852 17.6193 4.83638C17.2517 4.44693 15.2826 2.49966 12.5163 2.49966Z"
          fill={fill || `url(#${linearGradient1Id})`}
        />
        <path
          d="M12.5127 14.3798C10.2524 14.3798 8.41431 12.5418 8.41431 10.2815C8.41431 8.02118 10.2524 6.18311 12.5127 6.18311C14.773 6.18311 16.611 8.02118 16.611 10.2815C16.611 12.5418 14.773 14.3798 12.5127 14.3798ZM12.5127 8.07942C11.297 8.07942 10.3106 9.06943 10.3106 10.2815C10.3106 11.4935 11.3006 12.4835 12.5127 12.4835C13.7247 12.4835 14.7147 11.4935 14.7147 10.2815C14.7147 9.06943 13.7247 8.07942 12.5127 8.07942Z"
          fill={fill || '#10909E'}
        />
      </g>
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={linearGradient0Id}
          x1="12.4981"
          x2="12.4981"
          y1="30.8788"
          y2="22.8969"
        >
          <stop stopColor="#178A97" />
          <stop offset="1" stopColor="#59C4F1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id={linearGradient1Id}
          x1="21.6813"
          x2="3.36235"
          y1="13.5718"
          y2="13.5718"
        >
          <stop stopColor="#F8B62D" />
          <stop offset="1" stopColor="#E71F19" />
        </linearGradient>
        <clipPath id={clipPathId}>
          <rect fill="white" height="31" width="25" />
        </clipPath>
      </defs>
    </svg>
  )
}
